import classnames from 'classnames';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateRight,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import LazyTabPanel from '../../../../components/LazyTabPanel';
import CollapsedPanel from '../../../../components/CollapsedPanel';
import NewObjectApplicationActivityTab from './tabs/NewObjectApplicationActivityTab';
import NewObjectJobsTab from './tabs/NewObjectJobsTab';
import NewObjectNotificationsTab from './tabs/NewObjectNotificationsTab';
import NewObjectAlertsTab from './tabs/NewObjectAlertsTab';
import NewObjectAuditTrailTab from './tabs/NewObjectAuditTrailTab';
import NewObjectBridgePointsTab from './tabs/NewObjectBridgePointsTab';

const ACTIVITY_PANEL_TABS = {
  jobs: 'Jobs',
  application_activity: 'Application Activity',
  notifications: 'Notifications',
  alerts: 'Alerts',
  audit_trail: 'Audit Trail',
  bridgepoints: 'BridgePoints™',
};

function NewObjectActivityPanel({
  collapsedMode,
  collapsedPanels,
  setCollapsedPanels,
  expandChildPanel,
  detailsRef,
  bankRef,
  profileRef,
  activityRef,
  childGroupDefaultSizes,
  consumer,
  getConsumer,
  activityActiveTab,
  toggleActivityActiveTab,
  setPanelDataRefresh,
}) {
  const [refreshTab, setRefreshTab] = useState('');

  const refresh = () => setRefreshTab(activityActiveTab);

  const toggleActivityPanel = () => {
    if (collapsedPanels.details && collapsedPanels.profile && collapsedPanels.bank) {
      setCollapsedPanels(prev => ({ ...prev, details: false, profile: false, bank: false }));
      profileRef.current?.expand();
      detailsRef.current?.expand();
      bankRef.current?.expand();
    } else {
      setCollapsedPanels(prev => ({ ...prev, details: true, profile: true, bank: true }));
      profileRef.current?.collapse();
      detailsRef.current?.collapse();
      bankRef.current?.collapse();
    }
  };

  if (collapsedPanels.activity) {
    return (
      <CollapsedPanel
        side="up"
        onClick={() => expandChildPanel(activityRef, childGroupDefaultSizes[1])}
      />
    );
  }

  return (
    <div className="h-100 p-2 d-flex flex-column">
      <div className="d-flex justify-content-between">
        <Nav className="bo-new-object-nav border-bottom-0" tabs>
          {Object.entries(ACTIVITY_PANEL_TABS).map(([key, value]) => (
            <NavItem key={key}>
              <NavLink
                className={`bo-new-object-nav-link ${classnames({
                  'bo-new-object-nav-link-active': activityActiveTab === key,
                })}`}
                onClick={() => toggleActivityActiveTab(key)}
              >
                {value}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <div className="d-flex">
          <Button
            className="bo-new-object-inline-button me-2 d-flex align-items-center"
            onClick={refresh}
          >
            <FontAwesomeIcon icon={faArrowRotateRight} size="1x" color="#5946A4" />
          </Button>
          {!collapsedMode && (
            <Button
              className="bo-new-object-inline-button me-2 d-flex align-items-center"
              onClick={toggleActivityPanel}
            >
              <FontAwesomeIcon
                icon={
                  collapsedPanels.details && collapsedPanels.profile && collapsedPanels.bank
                    ? faCompressArrowsAlt
                    : faExpandArrowsAlt
                }
                size="1x"
                color="#5946A4"
              />
            </Button>
          )}
        </div>
      </div>
      <TabContent activeTab={activityActiveTab} className="bo-new-object-tabs">
        <LazyTabPanel activeTab={activityActiveTab} tabId="jobs">
          <NewObjectJobsTab
            consumerId={consumer.id}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
            setPanelDataRefresh={setPanelDataRefresh}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activityActiveTab} tabId="application_activity">
          <NewObjectApplicationActivityTab
            consumerId={consumer.id}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activityActiveTab} tabId="notifications">
          <NewObjectNotificationsTab
            consumerId={consumer.id}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activityActiveTab} tabId="alerts">
          <NewObjectAlertsTab
            consumerId={consumer.id}
            consumerAlerts={consumer.alerts}
            getConsumer={getConsumer}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activityActiveTab} tabId="audit_trail">
          <NewObjectAuditTrailTab
            consumerId={consumer.id}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
          />
        </LazyTabPanel>
        <LazyTabPanel activeTab={activityActiveTab} tabId="bridgepoints">
          <NewObjectBridgePointsTab
            consumerId={consumer.id}
            refreshTab={refreshTab}
            setRefreshTab={setRefreshTab}
            setPanelDataRefresh={setPanelDataRefresh}
          />
        </LazyTabPanel>
      </TabContent>
    </div>
  );
}

export default NewObjectActivityPanel;
